<template>
  <div>
    <div class="d-block">
      <base-section
        v-if="prominent"
        id="map"
        space="0"
        height="400"
      >
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/hkuSzD7ZlGY"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </base-section>
      <base-img
        v-if="!prominent"
        :height="prominent ? 400 : 250"
        :src="src"
        flat
        tile
      >
        <v-row
          class="ma-0 fill-height"
          align="end"
          justify="end"
        >
          <v-sheet
            class="pa-2 d-inline-flex align-center justify-center"
            color="primary"
            dark
            tile
            height="40"
            width="40"
          >
            <v-icon
              v-if="icon"
              v-text="icon"
            />
          </v-sheet>
        </v-row>
      </base-img>
    </div>

    <v-sheet
      class="d-flex"
      dark
      tile
      max-width="100%"
    >
      <div class="grow pa-2 text-body-2">
        <div class="d-flex align-center fill-height">
          <span
            class="px-2"
            v-text="formatDate(date)"
          />
          <span v-if="category"> / </span>
          <span
            v-if="category"
            class="px-2"
            v-text="category"
          />
          <span v-if="comments"> / </span>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <span
              v-if="comments"
              class="px-2"
              v-text="`${comments} Comments`"
            />
          </template>
        </div>
      </div>

      <v-sheet
        class="pa-2 d-md-inline-block text-center d-none"
        color="white"
        dark
        height="40"
        min-width="40"
        tile
        width="40"
      >
        <v-icon
          v-if="icon"
          v-text="icon"
        />
      </v-sheet>
    </v-sheet>

    <div class="pa-4">
      <base-title
        v-if="!prominent"
        :title="title"
        class="text-truncate text-uppercase"
      />
      <base-title
        v-if="prominent"
        :title="title"
        class="text-uppercase"
      />

      <base-body
        :text="!html ? truncatedText : undefined"
        :html="html"
        space="0"
      />
    </div>

    <v-btn
      v-if="readMore"
      class="font-weight-bold"
      text
      :href="link"
    >
      Read More
    </v-btn>

    <v-divider
      v-if="divider"
      class="mt-6"
    />
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'NewsCard',

    props: {
      category: String,
      comments: [Number, String],
      date: String,
      divider: Boolean,
      html: String,
      icon: String,
      prominent: Boolean,
      readMore: Boolean,
      src: String,
      text: String,
      title: String,
      truncate: [Number, String],
      link: String,
    },

    computed: {
      truncatedText () {
        if (!this.text) return ''

        const truncate = Number(this.truncate)

        return this.text.length > truncate
          ? this.text.slice(0, truncate) + ' [...]'
          : this.text
      },
    },

    methods: {
      formatDate (date) {
        if (moment(date).format('YYYY') === '0000') {
          return ''
        }

        if (moment(date).format('YYYY') === '0001') {
          return ''
        }

        return moment(date).format('MMM. DD, YYYY')
      },
    },
  }
</script>
